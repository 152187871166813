import React, { createContext, SyntheticEvent, useContext, useEffect, useState } from 'react'
import UserService from '../../../api/Users/Service'
import { v4 as uuid } from 'uuid'
import { CheckCountryCodeResponse, UpdatePreferredCurrencyResponse } from '../../../api/Users/Types'
import { currencyDivide, currencyMultiply } from '../Currency'

export const CURRENCY_TO_FLAG = {
  CAD: 'https://assets.vention.io/page-assets/localization/country-flags/ca.svg',
  USD: 'https://assets.vention.io/page-assets/localization/country-flags/us.svg',
  EUR: 'https://assets.vention.io/page-assets/localization/country-flags/eu.svg',
}

export const CONSIDER_EU = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
]

export const EU_AND_UK = [...CONSIDER_EU, 'GB']

// Shared with lib/country_code_helper.rb
export const COUNTRY_CODES_USING_EURO_CURRENCY = [
  ...CONSIDER_EU,
  'AD',
  'AE',
  'AM',
  'AO',
  'AZ',
  'BA',
  'BF',
  'BH',
  'BI',
  'BJ',
  'BW',
  'BY',
  'CF',
  'CH',
  'CM',
  'DJ',
  'DZ',
  'EG',
  'EH',
  'ER',
  'ET',
  'FO',
  'GA',
  'GE',
  'GH',
  'GM',
  'GN',
  'GQ',
  'IL',
  'IM',
  'IQ',
  'IS',
  'JO',
  'KE',
  'KM',
  'KW',
  'KZ',
  'LB',
  'LI',
  'LR',
  'LS',
  'LY',
  'MA',
  'MC',
  'ME',
  'MG',
  'ML',
  'MR',
  'MU',
  'MW',
  'MZ',
  'NA',
  'NE',
  'NG',
  'NO',
  'OM',
  'QA',
  'RS',
  'RW',
  'SA',
  'SC',
  'SD',
  'SL',
  'SM',
  'SN',
  'SO',
  'SS',
  'ST',
  'TD',
  'TG',
  'VN',
  'GB',
]

const NEW_SESSION_EVENT_NAME = 'new_currency_context_created'

interface CurrencyContextType extends State {
  readonly isUsingEuroCurrency: () => boolean
  readonly convertPriceFromBase: (price?: string | number | undefined) => number | undefined
  readonly convertPriceToBase: (price?: string | number | undefined) => number | undefined
  readonly currentExchangeRate: () => ExchangeRate
}

interface State {
  readonly isLoaded: boolean
  readonly userCurrency: string | null
  readonly displayedCurrency: string
  readonly countryCode: CheckCountryCodeResponse | null
  readonly exchangeRates: {
    readonly [key: string]: ExchangeRate
  }
  readonly currencyLabels: Record<string, string>
}

const CurrencyContext = createContext<CurrencyContextType>({} as CurrencyContextType)
CurrencyContext.displayName = 'CurrencyContext'

export const CurrencyConsumer = CurrencyContext.Consumer

interface Props {
  readonly skipCheckCountryCode?: boolean
}

const CurrencyProvider: React.FC<Props> = ({ skipCheckCountryCode, children }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [userCurrency, setUserCurrency] = useState<State['userCurrency']>(null)
  const [displayedCurrency, setDisplayedCurrency] = useState('')
  const [countryCode, setCountryCode] = useState<State['countryCode']>(null)
  const [exchangeRates, setExchangeRates] = useState<State['exchangeRates']>({})

  // needed to be able to quickly dispatch state updates
  const stateSetters = {
    isLoaded: setIsLoaded,
    userCurrency: setUserCurrency,
    displayedCurrency: setDisplayedCurrency,
    countryCode: setCountryCode,
    exchangeRates: setExchangeRates,
  }

  const uuidGenerated = uuid()

  useEffect(() => {
    window.addEventListener(NEW_SESSION_EVENT_NAME, receiveNewSessionContext)
    if (!skipCheckCountryCode) {
      checkCountryCode()
    } else {
      setIsLoaded(true)
      dispatchEvent('isLoaded', true)
    }
    return () => window.removeEventListener(NEW_SESSION_EVENT_NAME, receiveNewSessionContext)
  }, [])

  const receiveNewSessionContext = args => {
    if (args.detail.originator !== uuidGenerated) {
      const stateKey = args.detail.key
      const stateValue = args.detail.value
      stateSetters[stateKey](stateValue)
    }
  }

  const dispatchEvent = (key, value) => {
    window.dispatchEvent(
      new CustomEvent(NEW_SESSION_EVENT_NAME, {
        detail: { key, value, originator: uuidGenerated },
      })
    )
  }

  const getCountryCode = async () => {
    const countryCodeResponse = await UserService.checkCountryCode()
    setCountryCode(countryCodeResponse)
    dispatchEvent('countryCode', countryCodeResponse)
    return countryCodeResponse
  }

  const isUsingEuroCurrency = () => {
    return displayedCurrency === 'EUR'
  }

  const checkCountryCode = async () => {
    const selectedCurrencyCode = await getInitialCurrency()
    const countryCode = await getCountryCode()
    let currency: string

    if (COUNTRY_CODES_USING_EURO_CURRENCY.includes(countryCode)) {
      currency = 'EUR'
    } else if (countryCode === 'CA') {
      currency = 'CAD'
    } else {
      currency = 'USD'
    }

    setUserCurrency(currency)
    dispatchEvent('userCurrency', currency)

    setIsLoaded(true)
    dispatchEvent('isLoaded', true)
  }

  const getInitialCurrency = async () => {
    const selectedCurrencyDataResponse = await UserService.checkPreferredCurrency()
    const currencyCode = selectedCurrencyDataResponse.currency_code
    if (currencyCode) {
      setDisplayedCurrency(currencyCode)
      dispatchEvent('displayedCurrency', currencyCode)
    }

    // store all exchanges rates, to be used as needed
    setExchangeRates(selectedCurrencyDataResponse.exchange_rates)
    dispatchEvent('exchangeRates', selectedCurrencyDataResponse.exchange_rates)
    return currencyCode
  }

  const currencyLabels = Object.keys(exchangeRates).reduce((previous, currencyKey) => {
    const label = `${exchangeRates[currencyKey].currency_symbol}${exchangeRates[currencyKey].currency_code}`
    return { ...previous, [currencyKey]: label }
  }, {})

  const currentExchangeRate = () => {
    return exchangeRates[displayedCurrency]
  }

  const convertPrice = (operator: 'multiply' | 'division') => {
    return (price?: number | string) => {
      const exchangeRate = currentExchangeRate()

      if (!exchangeRate) {
        throw new Error('exchangeRate is null here, fetch before using this function.')
      }

      if (typeof price === 'undefined' || price === '') {
        return undefined
      }

      const numberInStringFormat = String(price).split(',').join('')
      const number = Number(numberInStringFormat)

      if (isNaN(number)) {
        throw new Error('argument price is not a compatible number.')
      }

      let value: number

      // keep high precision for rounding accuracy
      const currencyOptions = {
        precision: 8,
      }

      switch (operator) {
        case 'multiply':
          value = currencyMultiply(number, exchangeRate.rate, currencyOptions).value
          break
        case 'division':
          value = currencyDivide(number, exchangeRate.rate, currencyOptions).value
          break
      }

      return value
    }
  }

  const convertPriceFromBase = convertPrice('multiply')
  const convertPriceToBase = convertPrice('division')

  const contextValue = {
    isLoaded,
    userCurrency,
    displayedCurrency,
    countryCode,
    exchangeRates,
    currencyLabels,
    isUsingEuroCurrency,
    convertPriceFromBase,
    convertPriceToBase,
    currentExchangeRate,
  }

  return <CurrencyContext.Provider value={contextValue}>{children}</CurrencyContext.Provider>
}

export const useCurrencyContext = (): CurrencyContextType => {
  const context = useContext(CurrencyContext)
  if (!context) {
    throw new Error('useCurrencyContext must be used within a CurrencyProvider')
  }

  return context
}

export default CurrencyProvider
